<template>
  <router-view/>
</template>

<script>
export default {
  methods:{
    setFavicon() {
      const hostname = window.location.hostname;

      if (/\.help$/.test(hostname)) {
        const publicPath = '/';
        const faviconPath = `${publicPath}favicon-help.png`;
        const link = document.querySelector("link[rel~='icon']");

        if (!link) {
          const newLink = document.createElement('link');
          newLink.rel = 'icon';
          newLink.href = faviconPath;
          document.head.appendChild(newLink);
        } else {
          link.href = faviconPath;
        }
      }
    }
  },
  created() {
    this.setFavicon()
  }
}
</script>

<style lang="scss">
@import "~@/assets/fonts/fonts.scss";

body {
  margin: 0;
  padding: 0;
}

a{
  text-decoration: none;
}
</style>
